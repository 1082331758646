.ql-align-justify {
    text-align: justify;
}
.ql-align-center {
    text-align: center;
}
.ql-align-right {
    text-align: right;
}
.ql-align-left {
    text-align: left;
}